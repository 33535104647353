export const urlRegister = '/clients/register';
export const urlSocialGoogle = '/clients/google/connect';
export const urlSocialFacebook = '/clients/facebook/connect';
export const urlSocialApple = '/clients/apple/connect';
export const urlLoginPage = '/clients/login';
export const urlCheckSession= '/clients/checkSession';
export const urlLoginIntoFlow = '/clients/login-into-flow';
export const urlMe = '/clients/me';
export const urlGenerateToken = '/clients/generate-token';
export const urlSmsConfigurationCMS = '/sms-validation-configuration';
export const urlForgot = '/clients/forgot-password';
export const updateUser = '/clients/update';
export const createNewAccount = '/accounts/create';
export const urlCountryProbeCheck = '/clients/country/probe';
export const urlCountryChange = '/clients/country/change';
export const urlSendSMS = '/phone/sendSMS';
export const urlCheckSMS = '/phone/checkSMS';
export const countryList = '/countries';
export const localeList = '/locales';
export const translations = '/translations';
export const urlResidenceAddress = '/client/residenceAddress';
export const urlUploadFiles = '/clients/uploadFiles';
export const urlUpdateMe = '/clients/update';
export const phoneValidation = '/clients/phoneValidation';
export const urlGlobalConfiguration = '/global-configuration';
export const platformDefault = '/countries/details';
export const allLicenses = '/licenses';
export const allCurrencies = '/currencies';
export const urlQuiz = '/quizzes';
export const urlQuizUpdate= '/clients/updateQuiz';
export const urlUpdateMeIDV= '/clients/update-idv';
export const urlGetIdentityStatus= '/clients/identity-verification-status';
export const urlQuizComplete= '/clients/quizComplete';
export const urlRiskCautionAccept= '/clients/risk-caution-accept';
export const urlCompleteFullRegistration= '/clients/complete-full-registration';
export const urlCheckFullyRegister= '/clients/check-fully-register';
export const urlGetClientCurrentQuizStatus= '/clients/get-quiz-status';
export const urlDeposit= '/clients/get-deposit-link';
export const amountsDeposit= '/clients/get-deposit-amounts';
export const urlConversionList= '/currency-conversion/all';
export const urlGeoAPI = '/middleware/geo';
export const urlGetLatestAccount= '/accounts/get-latest-account';
export const urlIdentityConfiguration = '/identity-verification-configuration'
export const urlGetAccounts= '/accounts';
export const urlRegisterConfig= '/registration-form-configurations';
