const isBrowser = typeof window !== 'undefined'
const buildId = process.env.NEXT_PUBLIC_BUILD_ID;
module.exports = {
    i18n: {
        // locales: ['default', 'en', 'de', 'es', 'cs', 'ar', 'ru', 'bg', 'it', 'ro', 'ms', 'pl', 'vi', 'hi'],
        locales: ['default', 'en', 'es', 'ar', 'it', 'de','el'], // update also the middleware
        defaultLocale: 'default',
        localeDetection: false,
    },
    backend: {
        allowMultiLoading: false,
        loadPath: `${process.env.NEXT_PUBLIC_BE_HOST}/translations/{{lng}}?buildId=${buildId}`,
    },
    fallbackLng: 'en',
    // fallbackLng: false, // set to false, if you don't want to display english when there is missing translation on other language for example
    use: [
        require('i18next-http-backend')
    ]
  }
