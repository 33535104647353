import axios from "axios";
import { countryList, allLicenses, localeList, platformDefault, urlMe, urlQuiz,urlQuizComplete, urlQuizUpdate, allCurrencies, urlRiskCautionAccept, urlCompleteFullRegistration, urlGetClientCurrentQuizStatus, urlCheckFullyRegister } from "../utils/server-links";

export const requestUser = async (token: string) => {
    const instance = axios.create({
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    try {
        const response = await instance.get(`${process.env.NEXT_PUBLIC_BE_HOST}${urlMe}`)
        if (response.data) {
            return response.data
        } else {
            throw('Error getting user')
        }
    } catch (e) {
        return { error: 'Error getting user' };
    }
}

export const requestQuiz = async (token: string, quizId: number) => {
    const instance = axios.create({
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    try {
        const response = await instance.get(`${process.env.NEXT_PUBLIC_BE_HOST}${urlQuiz}/${quizId}`)
        if (response.data) {
            return response.data
        } else {
            throw('Error getting quiz')
        }
    } catch (e) {
        return { error: 'Error getting quiz' };
    }
}

export const updateQuiz = async (token: string, data: any, quizId: any, instrumentyTypeId: any, currentQuestionIndex?: any) => {
    if(data){
        const instance = axios.create({
            headers: {
                "Authorization": `Bearer ${token}`,
                'Screen-Origin-Url': window?.location?.href
            }
        })
    
        try {
            const response = await instance.put(`${process.env.NEXT_PUBLIC_BE_HOST}${urlQuizUpdate}`, { data, quizId: quizId, instrumentyTypeId:instrumentyTypeId, currentQuestionIndex })
            if (response.data) {
                return response.data
            } else {
                throw('Error updating quiz')
            }
        } catch (e) {
            return { error: 'Error updating quiz' };
        }
    }
}
export const completeQuiz = async (token: string, quizId: any, instrumentyTypeId: any,sendtoRemote:Boolean = true) => {
    const instance = axios.create({
        headers: {
            "Authorization": `Bearer ${token}`,
            'Screen-Origin-Url': window?.location?.href
        }
    })
    try {
        const response = await instance.put(`${process.env.NEXT_PUBLIC_BE_HOST}${urlQuizComplete}?sendtoRemote=${sendtoRemote}`, { quizId: quizId, instrumentyTypeId:instrumentyTypeId });
        if (response.data) {
            return response.data
        } else {
            throw('Error completing quiz')
        }
    } catch (e) {
        return { error: 'Error completing quiz' };
    }
}
export const riskCautionAccept = async (token: string) => {
    const instance = axios.create({
        headers: {
            "Authorization": `Bearer ${token}`,
            'Screen-Origin-Url': window?.location?.href
        }
    })
    try {
        const response = await instance.post(`${process.env.NEXT_PUBLIC_BE_HOST}${urlRiskCautionAccept}`);
        if (response.data) {
            return response.data
        } else {
            throw('Error accept the risk warning')
        }
    } catch (e) {
        return { error: 'Error accept the risk warning' };
    }
}
export const completeFullRegistration = async (token: string) => {
    const instance = axios.create({
        headers: {
            "Authorization": `Bearer ${token}`,
            'Screen-Origin-Url': window?.location?.href
        }
    })
    try {
        const response = await instance.post(`${process.env.NEXT_PUBLIC_BE_HOST}${urlCompleteFullRegistration}`);
        if (response.data) {
            return response.data
        } else {
            throw('Error full registration')
        }
    } catch (e) {
        return { error: 'Error full registration' };
    }
}
export const checkFullyRegister = async (token: string) => {
    const instance = axios.create({
        headers: {
            "Authorization": `Bearer ${token}`,
            'Screen-Origin-Url': window?.location?.href
        }
    })
    try {
        const response = await instance.get(`${process.env.NEXT_PUBLIC_BE_HOST}${urlCheckFullyRegister}`);
        if (response.data) {
            return response.data
        } else {
            throw('Error')
        }
    } catch (e) {
        return { error: 'Error checking full registration status' };
    }
}
export const getClientCurrentQuizStatus = async (token: string, quizId: any, instrumentId: any) => {
    const instance = axios.create({
        headers: {
            "Authorization": `Bearer ${token}`,
            'Screen-Origin-Url': window?.location?.href
        }
    })
    try {
        const response = await instance.post(`${process.env.NEXT_PUBLIC_BE_HOST}${urlGetClientCurrentQuizStatus}`,{
            quizId: quizId,
            instrumentTypeId: instrumentId
        });
        if (response.data) {
            return response.data
        } else {
            throw('Error getting quiz status')
        }
    } catch (e) {
        return { error: 'Error getting quiz status' };
    }
}

export const getCountries = async () => {
    try {
        const response = await axios.get(`${process.env.NEXT_PUBLIC_BE_HOST}${countryList}`);
        if (response.data) {
            return response.data
        } else {
            throw('Error fetching countries')
        }
    } catch (e) {
        return {error: 'Error fetching countries'};
    }
}

export const getLocales = async () => {
    try {
        const response = await axios.get(`${process.env.NEXT_PUBLIC_BE_HOST}${localeList}`);
        if (response.data) {
            return response.data
        } else {
            throw('Error fetching countries')
        }
    } catch (e) {
        return {error: 'Error fetching countries'};
    }
}

export const getAllLicenses = async (countryCode?: string | null) => {
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_BE_HOST}${allLicenses}?country=${countryCode}`);
        if (response.data) {
            return response.data
        } else {
            throw('Error fetching licenses')
        }
    } catch (e) {
      return {error: 'Error fetching licenses'};
    }
}

export const getAllCurrencies = async () => {
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_BE_HOST}${allCurrencies}`);
        if (response.data) {
            return response.data
        } else {
            throw('Error fetching currencies')
        }
    } catch (e) {
      return {error: 'Error fetching currencies'};
    }
}

export const getPlatformDefault = async (countryCode?: string | null) => {
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_BE_HOST}${platformDefault}${countryCode ? '?country=' + countryCode : ''}`);
        if (response.data) {
            return response.data
        } else {
            throw('Error fetching platform currency')
        }
    } catch (e) {
        return {error: 'Error fetching platform currency'};
    }
}
