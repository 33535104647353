import useStore from "@components/store";
import axios from "axios";
import {countryList, platformDefault, urlMe} from "./server-links";

export const removeCookie = (name: string) => {
    // @ts-ignore
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + process.env.NEXT_PUBLIC_PRODUCTION_URL + ";path=/";
    return null;
}

export const getCookie = (name: string) => {
    var nameEQ = name + "=";
    // @ts-ignore
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const useCookies = () => {
    const storeToken = useStore((state) => state.token);
    const getCookieNative = (name: string) => {
        var nameEQ = name + "=";
        // @ts-ignore
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    const getCookieHook = (name: string) => {
        const cookie: string | null = name === 'token' ? (getCookieNative("token") && getCookieNative("token")!=='null' ? getCookieNative("token") : storeToken) : getCookieNative(name);
        return cookie;
    };
  
    return getCookieHook;
  };

export const updateCookie = (name: string) => {
    const cookie = getCookie(name);
    var expires = "";
    var date = new Date();
    date.setTime(date.getTime() + 15 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();

    // @ts-ignore
    document.cookie = name + "=" + cookie + expires + ";domain=" + process.env.NEXT_PUBLIC_PRODUCTION_URL + ";path=/";
}

export const createCookie = (name: string, cookie: any) => {
    var expires = "";
    var date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();

    // @ts-ignore
    document.cookie = name + "=" + cookie + expires + ";domain=" + process.env.NEXT_PUBLIC_PRODUCTION_URL + ";path=/";
}

export const getCookieServerSide = (name: string, cookieServer: string = '') => {
    var nameEQ = name + "=";
    var ca = cookieServer.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export const routingApp = async (req: any, res: any, step: string) => {
    const token = getCookieServerSide('token', req ? req.headers.cookie || "" : document.cookie);
    if (token) {
        const instance = axios.create({
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        try {
            const response = await instance.get(`${process.env.NEXT_PUBLIC_BE_HOST}${urlMe}`)
            const onboardingStep = response.data.user.onboardingStep
            if (step != onboardingStep) {
                switch(onboardingStep) {
                case "1":
                    res.writeHead(302, {Location: '/user/residence-country', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "2":
                    res.writeHead(302, {Location: '/user/kyc', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "3":
                    res.writeHead(302, {Location: '/user/residence-address', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "4":
                    res.writeHead(302, {Location: '/user/poi', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "5":
                    res.writeHead(302, {Location: '/user/por', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "6":
                    res.writeHead(302, {Location: '/user/quiz', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "7":
                    res.writeHead(302, {Location: '/user/dma', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "11":
                    res.writeHead(302, {Location: '/user/marginal-fail', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "9":
                    res.writeHead(302, {Location: '/user/terms-conditions', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "8":
                    res.writeHead(302, {Location: '/user/mifid', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "10":
                    res.writeHead(302, {Location: '/user/cnmv', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "20":
                    res.writeHead(302, {Location: '/user/registration-complete', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "21":
                    res.writeHead(302, {Location: '/user/demo', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                case "22":
                    res.writeHead(302, {Location: '/user/full-registration', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                default:
                    res.writeHead(302, {Location: '/user/residence-country', 'Cache-Control': 'max-age=1'})
                    res.end();
                    break;
                }
            }
            return {};
        } catch(e) {
            if(step === '0') return{}
            res.writeHead(302, {Location: '/login', 'Cache-Control': 'max-age=1'})
            res.end();
            return {}
        } 
    } else if(!token && step != '0') {
        res.writeHead(302, {Location: '/login', 'Cache-Control': 'max-age=1'})
        res.end();
        return {} 
    }
    return null;
}

export const onboardingRoutes = (step: string) => {
    switch(step) {
        case "smsValidation":
            return '/user/sms-validation';
            break;
        case "residenceAndCitizenship":
            return '/user/residence-country';
            break;
        case "personalDetails":
            return '/user/kyc';
            break;
        case "residenceAddress":
            return '/user/residence-address';
            break;
        case "4":
            return '/user/poi';
            break;
        case "5":
            return '/user/por';
            break;
        case "quiz":
            return '/user/quiz';
            break;
        case "dma":
            return '/user/dma';
            break;
        case "demo-cfd":
            return '/user/demo';
            break;
        case "fullRegistrationScoreCalculations":
            return '/user/full-registration';
            break;
        case "marginalFail":
            return '/user/marginal-fail';
            break;
        case "termsAndConditions":
            return '/user/terms-conditions';
            break;
        case "depositFunds":
            return '/user/deposit';
            break;
        case "verificationIdentity":
            return '/user/identity';
            break;
        case "taxIdentificationNumber":
            return '/user/tax-identification-number';
            break;
        case "mifid":
            return '/user/mifid';
            break;
        case "cnmv":
            return '/user/cnmv';
            break;
        default:
            return '/user/residence-country';
            break;
    }
}
