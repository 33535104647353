import '../styles/globals.css';
//import '../styles/DatePicker.css';
import type { AppProps } from 'next/app';
// import '../components/i18n';
import { ThemeProvider } from 'next-themes';
import {MantineProvider, createEmotionCache} from '@mantine/core';
import { appWithTranslation } from 'next-i18next'
import Head from 'next/head'
import rtlPlugin from 'stylis-plugin-rtl';

const ltrCache = createEmotionCache({ key: 'mantine' });


const rtlCache = createEmotionCache({
  key: 'mantine-rtl',
  stylisPlugins: [rtlPlugin],
});



import nextI18nConfig from '../next-i18next.config'
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Script from 'next/script';
import useStore from '@components/store';

const COOKIE_BOT_DOMAIN_GROUP_ID="90dc860d-7d1c-4e0e-bdfb-5a42e9bf2775";

export function App({ Component, pageProps }: AppProps) {
  const {locale, locales, asPath, query, isReady } = useRouter();
  const user = useStore(store=>store.user);
  const geoCountry = useStore(store=>store.geoCountry);
  const defaultLicense = useStore(store=>store.defaultLicense);
  const [clarityLoaded, setClarityLoaded] = useState(false);
  const [clarityForcedSession, setClarityForcedSession] = useState(false);
  const setAllLicenses = useStore((state) => state.setAllLicenses);
  const [loadConsent, setLoadConsent] = useState(false);
  const [loadConsentLoaded, setLoadConsentLoaded] = useState(false);

  useEffect(()=>{
    if(isReady && query.iframe !== 'true' && !loadConsentLoaded) {
      setLoadConsent(true)
    }
  },[isReady])

  useEffect(()=>{
    if (user && user?.country){
      setAllLicenses(user?.country)
    }
  },[user])

  useEffect(()=>{
    if (typeof window !== 'undefined' && !window.clarityNext && window?.clarity){
      window.clarityNext = {
        initiated: true
      };
    }
    if (clarityLoaded && typeof window !== 'undefined' && window.clarity){
      if (user) {
        if (user?.customerId) {
          window.customerId = user?.customerId;
          window.clarityNext.customerId = `${user?.customerId}`;
          window.clarity('identify', user?.customerId);
          window.clarity('set', 'customerId', `${user?.customerId}`);
        }
        if (user?.companyId) {
          window.clarityNext.companyId = `${user?.companyId}`;
          window.clarity('set', 'companyId', `${user?.companyId}`);
        }
        if (user?.country) {
          window.clarityNext.country = `${user?.country}`;
          window.clarity('set', 'country', user?.country);
        }
      }
      if (geoCountry) {
        window.clarityNext.geoCountry = `${geoCountry}`;
      }
      if (locale){
        window.clarityNext.locale = `${locale}`;
        window.clarity('set', 'locale', `${locale}`);
      }
      if (defaultLicense) {
        window.clarityNext.defaultCompanyName = `${defaultLicense.name}`;
        window.clarityNext.defaultCompanyId = `${defaultLicense.companyId}`;
      }
    }
    if (window && window.clarity && !clarityForcedSession) {
      setClarityForcedSession(true);
      window.clarityNext.forcedUpgrade = true;
      // @ts-ignore
      window.clarity('upgrade', 'force-session-record-for-customers')
    }
  },[clarityLoaded, user, clarityForcedSession,geoCountry, defaultLicense, locale])

  useEffect(() => {
    document.body.dir = locale == 'ar' ? 'rtl' : "ltr";
  }, [locale]);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      </Head>
      <MantineProvider emotionCache={locale == 'ar' ? rtlCache : ltrCache} theme={{
          dir: locale == 'ar' ? 'rtl' : 'ltr',
          fontFamily: 'Source Sans Pro, sans-serif',
        }}>
        <ThemeProvider enableSystem={false} attribute="class">
          <Component {...pageProps} />
          {!asPath?.includes('/user/in-platform') && (
            <>
            {process.env.NEXT_PUBLIC_APP_ENV === 'production' && (
              <Script strategy="lazyOnload" id="gtag-manager">
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-PHJRCT4');`}
              </Script>
            )}
              
            {loadConsent &&
               <Script
                id="CookieBot"
                data-cbid={COOKIE_BOT_DOMAIN_GROUP_ID}
                src="https://consent.cookiebot.com/uc.js"
                onLoad={()=>{setLoadConsentLoaded(true)}}
              />
            }
            <Script
              id="ClarityScript"
              strategy='lazyOnload'
              onReady={()=>setClarityLoaded(true)}

              >
                {`(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i+"?ref=gtm2";y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window,document,"clarity","script","c1dzfffr50");`}
              </Script>
          </>
          )}
        </ThemeProvider>
      </MantineProvider>
    </>
  );
}
// @ts-ignore
export default appWithTranslation(App,nextI18nConfig);